<template>
  <v-container>
    <p
      class="section_titles"
      v-text="section_title"
    />
    <v-card
      v-if="!load_about"
      flat
      class="about_wrapper">
      <v-layout
        row
        justify-center
          wrap>
          <v-flex
            xs12
            sm12>
            <v-layout
              row
              justify-center>
              <p
                style="font-size: 20px !important; font-weight: normal !important"
                class="section_titles"
                v-text="'Contenido en español'"
              />
            </v-layout>
          </v-flex>
          <v-flex
            class="flex_about"
            xs12
            sm6>
            <v-textarea
              outlined
              label="Párrafo superior - Español"
              background-color="white"
              color="primary"
              v-model="about.esParrafoIzquierda"
            />
          </v-flex>
          <v-flex
            class="flex_about"
            xs12
            sm6>
            <v-textarea
              outlined
              label="Párrafo inferior - Español"
              background-color="white"
              color="primary"
              v-model="about.esParrafoDerecha"
            />
          </v-flex>
          <v-flex
            xs12
            sm12>
            <v-layout
              row
              justify-center>
              <p
                style="font-size: 20px !important; font-weight: normal !important"
                class="section_titles"
                v-text="'Contenido en ingles'"
              />
            </v-layout>
          </v-flex>
          <v-flex
            class="flex_about"
            xs12
            sm6>
            <v-textarea
              outlined
              label="Párrafo superior - Inglés"
              background-color="white"
              color="primary"
              v-model="about.enParrafoIzquierda"
            />
          </v-flex>
          <v-flex
            class="flex_about"
            xs12
            sm6>
            <v-textarea
              outlined
              label="Párrafo inferior - Inglés"
              background-color="white"
              color="primary"
              v-model="about.enParrafoDerecha"
            />
          </v-flex>
      </v-layout>
    </v-card>
    <v-card
    v-else
      flat
      color="transparent">
      <v-container
        style="margin-top: 20%">
        <v-layout
          row
          justify-center>
          <loading-animation />
        </v-layout>
      </v-container>
    </v-card>
    <div
      class="btn_send_wrapper">
      <v-tooltip
        bottom>
        <template 
          v-slot:activator="{ on }"
        >
          <span 
            v-on="on">
            <v-btn
              :disabled="load_about"
              @click="post_about"
              large
              class="btn_submit"
              icon>
              <v-icon
                color="primary"
                v-html="sumbitIcon"
              />
            </v-btn>
          </span>
        </template>
        <span
          v-html="'Confirmar cambios'"
        />
      </v-tooltip>
    </div>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    load_about: false,
    sumbitIcon: 'mdi-send',
    about: {},
    section_title: 'About'
  }),
  mounted () {
    this.get_about()
  },
  computed: {
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    }
  },
  methods: {
    get_about () {
      this.load_about = true
      db.get(`${uri}/api/v1/about/`, {})
        .then((response) => {
          this.load_about = false
          this.about = response.data
        })
        .catch((error) => {
          this.load_about = false
          this.$store.commit('toggle_alert', {color: 'fail', text: 'Hubo un error con la conexión. Favor de intentarlo más tarde.'})
        })
    },
    post_about () {
      this.load_about = true
      var body = new URLSearchParams()
      body.append('enParrafoDerecha', this.about.enParrafoDerecha)
      body.append('enParrafoIzquierda', this.about.enParrafoIzquierda)
      body.append('esParrafoDerecha', this.about.esParrafoDerecha)
      body.append('esParrafoIzquierda', this.about.esParrafoIzquierda)
      db.put(`${uri}/api/v1/about/`, body, {
        headers: {
          'Authorization': this.usr_token
        }
      })
        .then((response) => {
          this.load_about = false
          this.about = response.data
          this.$store.commit('toggle_alert', {color: 'success', text: "Se actualizó la información correctamente."})
        })
        .catch((error) => {
          this.load_about = false
          this.$store.commit('toggle_alert', {color: 'fail', text: error.response.data.message})
        })
    }
  }
}
</script>
<style scoped>
.btn_submit {
  margin: 0px !important
}
.flex_about {
  padding: 5px;
}
.btn_send_wrapper {
  -webkit-box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75);
  box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75); 
  border-radius: 100%;
  border-style: solid;
  border-color: #21345C;
  z-index: 10;
  position:fixed;
  bottom:0;
  right:0;
  margin-right: 10%;
  margin-bottom: 5%
}
.about_wrapper {
  background-color: transparent;
  width: 100%
}
</style>